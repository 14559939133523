import React from "react";
import Main from "./Main/Main";
import About from "./AboutUs/AboutUs";
import Clients from "./Clients/Clients";
import Frontpage from "./FrontPage/FrontPage"
import { UpOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';

const FirstPage = ({WindowSize}) => {

	const style = {
		height: 40,
		width: 45,
		padding : "0 4px",
		lineHeight: '40px',
		borderRadius: 4,
		backgroundColor: '#13A5df',
		color: '#ffffff',
		textAlign: 'center',
		fontSize: 20,
		display : "flex",
		alignItems : "center",
		justifyContent : "space-between"
	  };
	return <div >
<BackTop duration={600}>
	<div style={style}>
		
<span>UP</span>
<UpOutlined style={{fontSize : "14px"}}/>
	</div>

</BackTop>
			<Frontpage WindowSize={WindowSize}/>
			<About />
			<Main WindowSize={WindowSize} />
			<Clients />
			
		</div>
	
};

export default FirstPage;
