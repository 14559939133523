import React from "react";
import {
	Container,
	MainText,
	LiDiv,
	AnalysisContianer,
	Portfolio,
	ListsAndCar,
	CarouselR,
	TextDiv,
	CarouselDiv,
	CadContainer,
	AnalysisDiv,
	
} from "./styles";
import ImageGallery from "react-image-gallery";
import AnimatedHead from '../../Animated Heading/AnimatedHead';
import ListComponent from '../../List/ListComponent' 
const Analysis = () => {
	const [slideIndex, setSlideIndex] = React.useState("");
	const cad = [
		"Finite Element Modelling (FEM)",
		"Finite Element Analysis (FEA)",
		"Computational Fluid Dynamics (CFD)",
		"Reverse engineering analysis",
	];

	const analysis = [
		"Structures & System simulation",
		"Linear & non-linear",
		"Impact & crashworthiness",
		"Modal & Vibration",
		"Computational Fluid Dynamics",
		"Thermal",
	];

	const portfolio = [
		"STATIC ANALYSIS OF FRAME",
		"DYNAMIC IMPACT LOAD CASE",
		"STATIC AND QUASI STATIC FE ANALYSIS",
		"STRUCTURAL FEA OF CARRIAGE ASSEMBLY",
		"FEA - DOOR TRIM",
		"MOULD FLOW ANALYSIS",
		"THERMAL ANALYSIS",
	];

	const analysisImages = [
		{
			original: `${require("../../../Assets/AnalysisSvgs/analysis 1.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/analysis 1.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/analysis 2.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/analysis 2.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/analysis 3.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/analysis 3.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/analysis 4.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/analysis 4.svg")}`,
		},
	];

	const portfolioImages = [
		{
			original: `${require("../../../Assets/AnalysisSvgs/STATIC ANALYSIS OF FRAME.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/STATIC ANALYSIS OF FRAME.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/DYNAMIC IMPACT LOAD CASE.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/DYNAMIC IMPACT LOAD CASE.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/STATIC AND QUASI STATIC FE ANALYSIS.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/STATIC AND QUASI STATIC FE ANALYSIS.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/STRUCTURAL FEA OF CARRIAGE ASSEMBLY.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/STRUCTURAL FEA OF CARRIAGE ASSEMBLY.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/FEA - DOOR TRIM.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/FEA - DOOR TRIM.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/MOULD FLOW ANALYSIS.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/MOULD FLOW ANALYSIS.svg")}`,
		},
		{
			original: `${require("../../../Assets/AnalysisSvgs/THERMAL ANALYSIS.svg")}`,
			thumbnail: `${require("../../../Assets/AnalysisSvgs/THERMAL ANALYSIS.svg")}`,
		},
	];

	return (
		<Container>
			<AnalysisContianer>

				<TextDiv>
				<AnimatedHead Heading="Analysis"/>

					<MainText>
						With a team of expert engineers in house and access to industry’s
						best CAE software we provide computer aided engineering services in
						design optimization through finite element analysis of the model. We
						also provide services in simulation of computational fluid dynamics.
					</MainText>
				</TextDiv>
			</AnalysisContianer>

			<CadContainer>
			
				<AnimatedHead Line={true} Heading="CAD Services"/>

				<ListsAndCar>
				<LiDiv>
						{cad.map((item, index) => {
							return <ListComponent key={index} items={item} />;
						})}
					</LiDiv>

					<CarouselDiv>
					<img style={{width : "100%"}} src={require("../../../Assets/AnalysisSvgs/CAE services.gif")} alt="" />
					</CarouselDiv>
				</ListsAndCar>
			</CadContainer>

			<AnalysisDiv>
				<AnimatedHead Line={true} Heading="Analysis"/>
				
				<ListsAndCar>
				<LiDiv>
						{analysis.map((item, index) => {
							return <ListComponent key={index} items={item} />;
						})}
					</LiDiv>

					<CarouselDiv>
						<ImageGallery
							thumbnailPosition="left"
							autoPlay
							lazyLoad
							items={analysisImages}
							
						/>
					</CarouselDiv>
				</ListsAndCar>
			</AnalysisDiv>

			<Portfolio>
			<AnimatedHead Heading="Portfolio" Line={true}/>
			
				<ListsAndCar>
				<LiDiv>
						{portfolio.map((item, index) => {
							return <ListComponent key={index} items={item} />;
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
							thumbnailPosition="left"
							autoPlay
							onSlide={(e) => setSlideIndex(e)}
							lazyLoad
							items={portfolioImages}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</Portfolio>
		</Container>
	);
};

export default Analysis;
