import styled from "@emotion/styled";

export const Container = styled.div`
	background: url(${require("../../Assets/AboutUs.svg")});
	@media screen and (max-width: 600px) {
		background: url(${require("../../Assets/Mobile/aboutus/ats.svg")});
	background-repeat: no-repeat;
	background-attachment: fixed;
	}
	background-attachment: fixed;
	background-repeat: no-repeat;
	height: calc(100vh - 8vh);
	display: flex;
	justify-content: flex-start;
    -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-position: center;
`;

export const MainText = styled.p`
	font-size: clamp(0.5rem, 3vw, 1.5rem);
	@media screen and (max-width: 600px) {
		font-size: clamp(1.2rem, 3vw, 3rem);
	}
	font-weight: 600;
	text-align: center;
	color: #000000;
	line-height: 2rem;
`;

export const RightDiv = styled.div`
@media screen and (max-width: 600px) {
	width: 100%;
	padding: 0 2%;

}
	width: 60%;
	padding-left: 2%;
`;
export const ListDiv = styled.ul`
	display: flex;
    flex-flow: column wrap;
    height: 200px;
	@media screen and (max-width: 600px) {
		align-items : center;
	}
	
	line-height : 40px;
	padding-left: 4%;
	@media screen and (max-width: 600px) {
		flex-flow: column ;	
	}

`;
export const ListText = styled.li`
	font-size: clamp(0.5rem, 3vw, 1.4rem);
	@media screen and (max-width: 600px) {
		font-size: clamp(1rem, 3vw, 5rem);
		font-weight : bold;
	}
`;
export const MemberContainer = styled.div`
@media screen and (max-width: 600px) {
	flex-flow: column ;	
}
padding-top : 30vh;
	width: 100%;
	height: calc(100vh - 8vh);
	@media screen and (max-width: 600px) {
		height: fit-content;


	}
    display: flex;
    background: url(${require("../../Assets/meetour.svg")});
 -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
    background-position: center;
    justify-content : space-around;
`;
export const HeaderText = styled.span`
	font-size: 40px;
	font-weight: 700;
	@media screen and (max-width: 600px) {
		font-size: 30px;
	}
	text-align: center;
	width: 100%;
	color: #14a5de;
	text-decoration-color: #fbd34d;
	text-decoration-line: underline;
	/* background: #fbd34c; */
	text-decoration-style: solid;
	text-underline-offset: 20px;
	text-decoration-thickness: 2px;
`;
export const HeaderSpan = styled.span`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 4% 0;
`;

export const DetailText = styled.div`
    height: fit-content;
    text-align : center;
	width: 20%;
	@media screen and (max-width: 600px) {
		width: 100%;
		font-size: clamp(1rem, 3vw, 5rem);
		text-align : center;
		padding : 15px;
	}
	font-size: clamp(0.5rem, 3vw, 1.2rem);
	
`;
