import styled from "@emotion/styled";

export const HeaderText = styled.p`
font-size: 40px;
@media screen and (max-width: 600px) {
	font-size: 30px;
}
	font-weight: 700;
	text-align: center;
	// width: fit-content;
	color: #14a5de;
	padding: 4%;
	margin: 0;
`;

export const Container = styled.div`
	@media screen and (max-width: 600px) {
		background: url(${require("../../../Assets/Mobile/aboutus/bulb.svg")});
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-position: center;
    justify-content : center;
	height: 500px;
	}

	display: flex;
	background: url(${require("../../../Assets/idea.svg")});
	height: 92vh;
	width: 100%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-position: center;
	flex-direction: column;
	background-attachment: fixed;
`;

export const TextContainer = styled.div`
	display: flex;
	height: fit-content;
    
`;
export const MainText = styled.p`
	@media screen and (max-width: 600px) {
		width: 100%;
		padding: 2% 15%;
	line-height: 1.5rem;
height : fit-content;
	}
	font-size: clamp(0.5rem, 3vw, 1.5rem);
	font-weight: 600;
	width: 50%;
	padding-left: 5%;
	text-align: justify;
	color: #000000;
	line-height: 3rem;
`;
export const DarkText = styled.span`
	// font-weight : bold;
`;
export const HeaderSpan = styled.span`
display: flex;
justify-content: center;
width: 50%;
padding-left: 5%;
@media screen and (max-width: 600px) {
    width: 100%;
    padding: 5%;
}
`;
