import React from "react";
import {
	Container,
	MainText,
	HeaderText,
	RightDiv,
	ListDiv,
	ListText,
	MemberContainer,
	HeaderSpan,
	DetailText,
} from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

const About = () => {
	const list = [
		"Engineering services",
		"Reverse engineering",
		"Industrial automation",
		"Automotive industry",
		"Rapid prototyping",
		"Manufacturing",
		"CNC machining",
		"Finite element analysis",
		"Embedded systems software and hardware",
		"Soap, detergents and Food industry",

	];
	return (
		<div>
			<Container>
				<RightDiv>
					<ScrollAnimation animateIn="fadeInLeft" delay={0}>
						<HeaderSpan style={{ display: "flex", justifyContent: "center" }}>
							<HeaderText>About Us</HeaderText>
						</HeaderSpan>
					</ScrollAnimation>
					<MainText>
						We actively partner with our customers to expand our expertise to
						develop optimal solution to maximize profits and revenues in the
						field of:
					</MainText>

					<ListDiv>
							{list.map((item, index) => {
								return <ListText>{item}</ListText>;
							})}
					</ListDiv>
				</RightDiv>
			</Container>
			<MemberContainer>
				<DetailText>
                    <b>Manohar MG</b>
                    <br/>
					28 Years of experience in Project management Operating a business
					Module Design of Jigs and Fixtures, Concept &amp; Special purpose
					machines, Transfer lines, Manufacturing, Assembly, New Product
					development, Semiconductor &amp; Capital Equipment Manufacturing
					companies.
				</DetailText>
				<DetailText>
                <b>Dinesh C</b>
                    <br/>
					30 years of Industrial experience in Electrical, Semiconductor &amp;
					automotive industries. Executed major turnkey projects including
					design to production level activities. Developed defense related
					equipment&#39;s for Navy and offshore. Developed Telecom and
					mechatronic products for international market.
				</DetailText>
				<DetailText>
                <b>Nagesh SP Rao</b>
                    <br/>
					26 years of Industrial experience in Design and manufacture of plastic
					injection molding tools, sheet metal parts, Jigs and Fixtures and
					machining of precision parts for various industrial segments like HP,
					GE, Philips, Danfoss Industries, Forus Health and more.
				</DetailText>
			</MemberContainer>
		</div>
	);
};

export default About;
