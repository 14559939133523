import React,{useRef} from "react";
import "./App.css";
import ContactUs from "./Components/ContactUs/ContactUs";
import FirstPage from "./Components/FirstPage/FirstPage";
import Subdesign from "./Components/Subpages/Design/Subdesign";
import Analysis from "./Components/Subpages/Analysis/Analysis";
import Consultancy from "./Components/Subpages/Consulting/Consulting";
import Electronics from "./Components/Subpages/Electronics/Electronics";
import Manufacture from "./Components/Subpages/Manufacture/Manufacture";
import ScrollToTop from "./Components/ScrollTotop/Scroll";
import Gallery from "./Components/Gallery/Gallery";
import Header from "./Components/Header/Header";
import MobileHeader from './Components/Mobile/Header/HeaderMobile'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import About from "./Components/AboutUs/About";

function App() {

	const bottomRef = useRef();
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
		});
	};
	const clientRef = useRef();
    const scrollToClient = () => {
        clientRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
		});
	};



	const size = useWindowSize();

	function useWindowSize() {
		// Initialize state with undefined width/height so server and client renders match
		// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
		const [windowSize, setWindowSize] = React.useState({
			width: undefined,
			height: undefined,
		});

		React.useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}

			// Add event listener
			window.addEventListener("resize", handleResize);

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount

		return windowSize;
	}
	const WindowSize = size.width;

	const [spinner, setSpinner] = React.useState(true);
	React.useEffect(() => {
		setTimeout(() => setSpinner(false), 2500);
	}, []);
	return spinner ? (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				height: "100vh",
				alignItems: "center",
			}}>
			<img src={require("./Assets/loading.svg")} alt="" />{" "}
		</div>
	) : (
		<div>
			<BrowserRouter>
			{WindowSize > 600 ? <Header scrollToBottom={scrollToBottom}  scrollToClient={scrollToClient}/> : <MobileHeader scrollToBottom={scrollToBottom}/> }
				

				<Switch>
					<Route exact path="/">
						<FirstPage spinner={spinner} WindowSize={WindowSize} />
					</Route>
					<Route path="/about-us">
						<ScrollToTop />
						<About />
					</Route>
					<Route path="/design">
						<ScrollToTop />
						<Subdesign WindowSize={WindowSize}/>
						
					</Route>
					<Route path="/analysis">
						<ScrollToTop />
						<Analysis />
						
					</Route>
					<Route path="/electrical-electronics">
						<ScrollToTop />
						<Electronics />
					
					</Route>
					<Route path="/consultancy">
						<ScrollToTop />
						<Consultancy />
				
					</Route>
					<Route path="/manufacturing-prototyping">
						<ScrollToTop />
						<Manufacture />
					</Route>
					<Route path="/gallery">
						<Gallery />	
					</Route>
					<Route path="*">
						<>
							{/* <h1>NOT FOUND</h1> */}
							<MobileHeader/>
						</>
					</Route>
				</Switch>
				<span ref={bottomRef} >
				<ContactUs />
				</span>

			</BrowserRouter>
		</div>
	);
}

export default App;
