import React, { useState } from "react";
import {useSpring, animated} from 'react-spring'

import {
	Container,
	One,
	Two,
	Three,
	Four,
	Five,
	GridContainer,
	Vertical,
	CurvedRec,
	LearnButton,
	RectText,
	HeaderText,
	HeaderSpan,
	ContainerWithTitle,
	ContainerWithContent
} from "./style";
import { useHistory } from "react-router-dom";
const Main = () => {
	const history = useHistory();
	const [inHover, setHover] = useState(false);
	const [twoHover, setTwoHover] = useState(false);
	const [threeHover, setThreeHover] = useState(false);
	const [fourHover, setFourHover] = useState(false);
	const [fiveHover, setFiveHover] = useState(false);
	const props = useSpring({opacity: inHover ? 1 : 0,delay : 600})
	const propstwo = useSpring({opacity: threeHover ? 1 : 0,delay : 600})
	const propsthree = useSpring({opacity: twoHover ? 1 : 0,delay : 600})
	const propsfour = useSpring({opacity: fourHover ? 1 : 0,delay : 600})
	const propsfive = useSpring({opacity: fiveHover ? 1 : 0,delay : 600})

	

const listWindow = [
	["New Product Development Designs",
		"Hydraulic and Electrical Circuit Designs",
		"Existing Design Optimization", 
		"Reverse Engineered Designs",
		],
		["Structures & System simulation",
			"Linear & non-linear",
			"Impact & Crashworthiness",
			"Modal & Vibration",
			"Computational Fluid Dynamics",
			"Thermal",
			],
			["Vertical Milling Machine Operations",
				"Turning Centre Operations",
			   "CNC Bending",
			   "Acrylic Laser Cutting",
				"Ram Turret Milling",
			   "Wire Frame 3D Printing",
			
			],
			[
				"Embedded Hardware",
"Industrial Automation",
"Software Turnkey Design Services",
 "INDUSTRIAL NETWORKING",
			],

			[ "Project Design",
				"Process Enhancement",
				"Quality Management",
				"Team Management", 
				"Project Profitability",
				"Optimal Utilization Of Resources",
				 "Manufacturing Excellence"
				]
]

	return (
		<GridContainer>
			<HeaderSpan style={{ display: "flex", justifyContent: "center" }}>
				<HeaderText>Services Provided</HeaderText>
			</HeaderSpan>
			<Container>
				<One
					style={{
						backgroundImage: inHover
							? `url(${require("../../../Assets/analysis.svg")})`
							: `url(${require("../../../Assets/analysis.svg")})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						objectFit: "cover",
						backgroundRepeat: "no-repeat",
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}>
					{" "}
					{inHover ? (
						<ContainerWithContent >
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "start",
									width: "100%",
									opacity: "1",
								}}>
								<Vertical>Analysis </Vertical>
							</div>
<div style={{width : "100%" ,height : "100%", display : "flex" , alignItems : "center" , }}>
							<animated.div style={props}>

							<CurvedRec>
								{" "}
								<RectText>
								<ul style={{lineHeight :"190%"}}>
									{listWindow[1].map((item , index)=>{
									
										return(<li key={index} >{item}</li>)
									})

								}
								</ul>
								</RectText>{" "}
								<span style={{display : "flex" , width : "100%" , justifyContent : "flex-end"}} >
								<LearnButton onClick={()=>{history.push('/analysis')}} type="primary">Learn more</LearnButton>{" "}
							</span>
							</CurvedRec>
							</animated.div>
								</div>
						</ContainerWithContent>
					) : (
						<ContainerWithTitle>
							<Vertical>Analysis</Vertical>
						</ContainerWithTitle>
					)}
				</One>

				<Three
					style={{
						backgroundImage: threeHover
							? `url(${require("../../../Assets/manufacture.svg")})`
							: `url(${require("../../../Assets/manufacture.svg")})`,
						backgroundPosition: threeHover ? "center" : "right",
						backgroundSize: "cover",
						objectFit: "cover",
						backgroundRepeat: "no-repeat",
					}}
					onMouseEnter={() => setThreeHover(true)}
					onMouseLeave={() => setThreeHover(false)}>
					{" "}
					{threeHover ? (
						<ContainerWithContent style={{ width: "100%" }}>
							{" "}
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "start",
									width: "100%",
									opacity: "1",
								}}>
								<Vertical inHover={inHover}>
								Manufacturing and
								<br /> Prototyping{" "}
							</Vertical>
							</div>
							
<div style={{width : "100%" ,height : "90%", display : "flex" , alignItems : "center" , }}>
							<animated.div style={propstwo}>
							<CurvedRec>
								{" "}
								<RectText>
								<ul style={{lineHeight :"190%"}}>
									{listWindow[2].map((item , index)=>{
									
										return(<li key={index} >{item}</li>)
									})

								}
								</ul>
								</RectText>{" "}
								<span style={{display : "flex" , width : "100%" , justifyContent : "flex-end"}} >
								<LearnButton onClick={()=>{history.push('/manufacturing-prototyping')}} type="primary">Learn more</LearnButton>{" "}
							</span>
							</CurvedRec>{" "}
							</animated.div>
							</div>
						</ContainerWithContent>
					) : (
						<ContainerWithTitle>
							<Vertical inHover={inHover}>
								Manufacturing and
								<br /> Prototyping{" "}
							</Vertical>
						</ContainerWithTitle>
					)}
				</Three>

				<Two
					style={{
						backgroundImage: twoHover
							? `url(${require("../../../Assets/design.svg")})`
							: `url(${require("../../../Assets/design.svg")})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						objectFit: "cover",
						backgroundRepeat: "no-repeat",
					}}
					onMouseEnter={() => setTwoHover(true)}
					onMouseLeave={() => setTwoHover(false)}>
					{" "}
					{twoHover ? (
						<ContainerWithContent style={{ width: "100%" }}>
							{" "}
							<ContainerWithTitle>
							<Vertical>Design </Vertical>{" "}
							</ContainerWithTitle>
							<div style={{width : "100%" ,height : "90%", display : "flex" , alignItems : "center" , }}>
							<animated.div style={propsthree}>

							<CurvedRec>
								{" "}
								<RectText>
								<ul style={{lineHeight :"190%"}}>
									{listWindow[0].map((item , index)=>{
									
										return(<li key={index} >{item}</li>)
									})

								}
								</ul>
								</RectText>{" "}
								<span style={{display : "flex" , width : "100%" , justifyContent : "flex-end"}} >
								<LearnButton onClick={()=>{history.push('/design')}} type="primary">Learn More</LearnButton>{" "}
								</span>
							</CurvedRec>
							</animated.div>
							</div>
						</ContainerWithContent>
					) : (
						<ContainerWithTitle>
							<Vertical>Design </Vertical>
						</ContainerWithTitle>
					)}
				</Two>

				<Four
					style={{
						backgroundImage: fourHover
							? `url(${require("../../../Assets/electronics.svg")})`
							: `url(${require("../../../Assets/electronics.svg")})`,
						backgroundPosition: "left",
						backgroundSize: "cover",
						objectFit: "cover",
						backgroundRepeat: "no-repeat",
					}}
					onMouseEnter={() => setFourHover(true)}
					onMouseLeave={() => setFourHover(false)}>
					{" "}
					{fourHover ? (
							<ContainerWithContent style={{ width: "100%" }}>
							{" "}
							<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "start",
								width: "100%",
								opacity: "1",
							}}>
							<Vertical >
								Electrical <br />
								and
								<br /> Electronics{" "}
							</Vertical>
							</div>
						
							
							<div style={{width : "100%" ,height : "80%", display : "flex" , alignItems : "center" , }}>
							
							<animated.div style={propsfour}>

							<CurvedRec>
								{" "}
								<RectText>
								<ul style={{lineHeight :"190%"}}>
									{listWindow[3].map((item , index)=>{
									
										return(<li key={index} >{item}</li>)
									})

								}
								</ul>
								</RectText>{" "}
								<span style={{display : "flex" , width : "100%" , justifyContent : "flex-end"}} >

								<LearnButton onClick={()=>{history.push('/electrical-electronics')}} type="primary">Learn more</LearnButton>{" "}
							</span>
							</CurvedRec>
							</animated.div>
							</div>
						</ContainerWithContent>
					) : (
						<ContainerWithTitle>
							<Vertical>
								Electrical <br />
								and
								<br /> Electronics{" "}
							</Vertical>
						</ContainerWithTitle>
					)}
				</Four>
				<Five
					style={{
						backgroundImage: fiveHover
							? `url(${require("../../../Assets/consultancy.svg")})`
							: `url(${require("../../../Assets/consultancy.svg")})`,
						backgroundPosition: "left",
						backgroundSize: "cover",
						objectFit: "cover",
						backgroundRepeat: "no-repeat",
					}}
					onMouseEnter={() => setFiveHover(true)}
					onMouseLeave={() => setFiveHover(false)}>
					{" "}
					{fiveHover ? (
						<ContainerWithContent style={{ width: "100%" }}>
							<ContainerWithTitle>
							<Vertical >consultancy </Vertical>
							</ContainerWithTitle>
							
							<div style={{width : "100%" ,height : "90%", display : "flex" , alignItems : "center" , }}>
							
							<animated.div style={propsfive}>

							<CurvedRec>
								{" "}
								<RectText>
								<ul style={{lineHeight :"190%"}}>
									{listWindow[4].map((item , index)=>{
									
										return(<li key={index} >{item}</li>)
									})

								}
								</ul>
								</RectText>{" "}
								<span style={{display : "flex" , width : "100%" , justifyContent : "flex-end"}} >

								<LearnButton onClick={()=>{history.push('/consultancy')}} type="primary">Learn more</LearnButton>{" "}
							</span>
							</CurvedRec>{" "}
							</animated.div>
							</div>
						</ContainerWithContent>
					) : (
						<ContainerWithTitle>
							<Vertical>consultancy </Vertical>
						</ContainerWithTitle>
					)}
				</Five>
			</Container>
		</GridContainer>
	);
};

export default Main;
