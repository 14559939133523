import styled from "@emotion/styled";
import ImageGallery from "react-image-gallery";
export const Container = styled.div``;

export const MainText = styled.p`
padding-top : 3%;
	font-size: clamp(12px, 19px, 2vw);
	font-weight: 500;
	text-align: justify;
	color: #ffffff;
	width: 100%;
	padding-left: 30px;
	padding-right: 30px;
	@media screen and (max-width: 600px) {
		font-size: clamp(1.2rem, 3vw, 3rem);
	color: #000000;

	}
`;



export const LiDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex: 0.5;
	height: auto;
	padding-left : 2%;
	@media screen and (max-width: 600px) {
		padding : 20% 0;
	}
`;

export const MobileText = styled.div`
@media screen and (max-width: 600px) {
	height : fit-content;
	display : contents;
	}
	display : none;
`


export const DesignContianer = styled.div`
	background: url(${require("../../../Assets/design.svg")});
	background-size: cover;
	background-repeat: no-repeat;
	@media screen and (max-width: 600px) {
		height : 100vh;
	background: url(${require("../../../Assets/Mobile/Subpages/design.svg")});

		}
`;

export const Portfolio = styled.div`
padding-top : 2%;

	height: 100vh;
	width: 100%;
	@media screen and (max-width: 600px) {
		height : fit-content;
		}
`;

export const ListsAndCar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top : 3%;
	align-items : center;
	@media screen and (max-width: 600px) {
		flex-direction : column-reverse;
		align-items : center;
	display: flex;
	
		}
`;
export const CarouselR = styled(ImageGallery)``;

export const TextDiv = styled.div`
	width: 30%;
height: calc(100vh - 15vh);
	flex-direction: column;
	display: flex;
	justify-content : center;
	@media screen and (max-width: 600px) {
height: fit-content;
width : 100%;
		
			}
`;

export const CarouselDiv = styled.div`
	flex: 1;
	@media screen and (max-width: 600px) {
		width: 100%;
	height : fit-content;
	padding-top : 10%;

			}
`;

export const CadContainer = styled.div`
padding-top : 2%;
height: 100vh;
width: 100%;
@media screen and (max-width: 600px) {
	height : fit-content;
	}
`

