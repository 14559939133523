import React from "react";
import { Container, HeaderText , DesktopImage,MobileImage } from "./styles";
function Clients() {
	return (
		<Container>
			<span style={{width : "100%" ,display : "flex", justifyContent : "center"}}>
			<HeaderText>Our Clients</HeaderText>
			</span>
			
			<DesktopImage src={require("../../../Assets/clientlogo.svg")} style={{height : "100%"}} width="100%" alt=""/>
			<MobileImage src={require("../../../Assets/Mobile/client.svg")} style={{height : "100%"}} width="100%" alt=""/>
		</Container>
	);
}

export default Clients;
