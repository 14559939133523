import React from "react";
import {
	Container,
	MapContainer,
	ContactContainer,
	ContactSpan,
} from "./styles";

const ContactUs = () => {
const Details = (name , number , mail)=>{
	return (<ContactSpan>
		<span>{name}</span>
		<br />
		<span style={{display : "flex", alignItems : "center"}}>
		{mail}
		<svg width="25" height="25" viewBox="0 0 25 25" style={{margin : "0 5px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.6562 3.90625H2.34375C1.91162 3.90625 1.5625 4.25537 1.5625 4.6875V20.3125C1.5625 20.7446 1.91162 21.0938 2.34375 21.0938H22.6562C23.0884 21.0938 23.4375 20.7446 23.4375 20.3125V4.6875C23.4375 4.25537 23.0884 3.90625 22.6562 3.90625ZM21.6797 6.61133V19.3359H3.32031V6.61133L2.64648 6.08643L3.60596 4.85352L4.65088 5.6665H20.3516L21.3965 4.85352L22.356 6.08643L21.6797 6.61133V6.61133ZM20.3516 5.66406L12.5 11.7676L4.64844 5.66406L3.60352 4.85107L2.64404 6.08398L3.31787 6.60889L11.6577 13.0933C11.8976 13.2796 12.1926 13.3807 12.4963 13.3807C12.8001 13.3807 13.0951 13.2796 13.335 13.0933L21.6797 6.61133L22.3535 6.08643L21.394 4.85352L20.3516 5.66406Z" fill="white"/>
</svg>
		</span>
		
		<span style={{display : "flex", alignItems : "center"}}>
		{number}

		<svg width="25" height="25" style={{margin : "0 5px"}} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="25" height="25" fill="#030303"/>
<path d="M17.3611 2.77783H7.63889C7.27053 2.77783 6.91726 2.92416 6.6568 3.18463C6.39633 3.4451 6.25 3.79836 6.25 4.16672V20.8334C6.25 21.2017 6.39633 21.555 6.6568 21.8155C6.91726 22.0759 7.27053 22.2223 7.63889 22.2223H17.3611C17.7295 22.2223 18.0827 22.0759 18.3432 21.8155C18.6037 21.555 18.75 21.2017 18.75 20.8334V4.16672C18.75 3.79836 18.6037 3.4451 18.3432 3.18463C18.0827 2.92416 17.7295 2.77783 17.3611 2.77783V2.77783ZM13.1944 20.8334H11.8056V19.4445H13.1944V20.8334ZM7.63889 18.0556V4.16672H17.3611V18.0556H7.63889Z" fill="white"/>
</svg>
		</span>
	</ContactSpan>)
}
// style={{color: "white", position: "relative", top: "65vh", marginLeft : "7px",}}
	return (
		<Container>
			<h1 style={{ textAlign: "center", color: "white" }}>Contact Us</h1>
			<ContactContainer>
				<div>
					{Details("Manohar M G" , "+919902244888" , "manohar@aadhyatechsol.com")}
					<br/>
					{Details("Dinesh C" , "+919972111244" , "dinesh@aadhyatechsol.com")}
					<br/>
					{Details("Nagesh R.S" )}
					
				</div>

				<div>
					<MapContainer>
						<iframe
							title="map"
							src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d62212.51701001684!2d77.47682341083011!3d12.953778312007463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x3bae3d133ac4dc73%3A0x4c01c2b1bbf53587!2sAADHYA%20Tech%20Sol%2C%20%23473%2F4%2C%201st%20Floor%2C%204th%20Main%2C%201st%20Cross%2C%20Srigandhakavalu%2C%20Kottigepalya%2C%20VN%20Post%2C%20Bengaluru%2C%20Karnataka%20560091!3m2!1d12.9848065!2d77.51161019999999!5e0!3m2!1sen!2sin!4v1611056128092!5m2!1sen!2sin"
							frameborder="0"
							style={{ border: 0, width: "100%", height: "100%" }}
							allowfullscreen=""
							aria-hidden="false"
							tabindex="0"></iframe>
					</MapContainer>
					<br/>
	<p style={{color : "white"}}>
						Address: #473/4, 1st Floor, 4th Main, <br/>1st Cross, Srigandhakavalu,
						Kottigepalya, VN Post, <br/>Bengaluru, Karnataka 560091
		</p>				
				
				</div>

			</ContactContainer>
			<p style={{color : "white" , textAlign : "center"}} >Copyright 2021 ATS | All Rights Reserved</p>

		</Container>
	);
};

export default ContactUs;
