import styled from "@emotion/styled";
import ImageGallery from "react-image-gallery";
export const Container = styled.div`
`;

export const MainText = styled.p`
	font-size: clamp(12px, 19px, 2vw);
	font-weight: 500;
	text-align: justify;
	color: white;
	width: 80%;
	background: rgba(0,0,0,0.7);
	border-radius: 20px;
	padding : 3%;
	margin-left: 30px;
	@media screen and (max-width: 600px) {
		font-size: clamp(1.2rem, 3vw, 3rem);
	}
`;
export const HeaderText = styled.span`
font-size: 40px;
font-weight: 700;
text-align: center;
width: 100%;
color: #14a5de;
text-decoration-color: #FBD34D;
text-decoration-line: underline;
/* background: #fbd34c; */
text-decoration-style: solid;
text-underline-offset: 20px;
text-decoration-thickness: 2px;
`;

export const LiDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex: 0.5;
	height: max-content;
    padding-left: 2%;
    align-self : center;  
	@media screen and (max-width: 600px) {
		padding : 20% 0;
	}  
`;

export const UlText = styled.li`
	font-size: 12px;
	font-weight: 500;
	color: #000000;
	line-height: 35px;
	@media screen and (max-width: 600px) {
		line-height: 20px;
		}

`;

export const ElectricalContainer = styled.div`
	background: url(${require("../../../Assets/electronics.svg")});
	background-size: cover;
	background-repeat: no-repeat;
	object-fit: cover;
	@media screen and (max-width: 600px) {
		height : fit-content;
	background: url(${require("../../../Assets/Mobile/Subpages/electrical.svg")});

		}

`;

export const Portfolio = styled.div`
	padding-top: 2%;
	@media screen and (max-width: 600px) {
		height : fit-content;
		}
	height: 100vh;
	width: 100%;
`;

export const ListsAndCar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 3%;
	@media screen and (max-width: 600px) {
		flex-direction : column-reverse;
		align-items : center;
	display: flex;
	
		}
`;
export const CarouselR = styled(ImageGallery)``;

export const TextDiv = styled.div`
height: calc(100vh - 8vh);
	width: 35%;
	flex-direction: column;
	display: flex;
	justify-content : center;
	@media screen and (max-width: 600px) {
		width: 70%;
		height : fit-content;
			}
`;

export const CarouselDiv = styled.div`
	flex: 1;
	@media screen and (max-width: 600px) {
		width: 100%;
	height : fit-content;
	padding-top : 10%;

			}
`;

export const CadContainer = styled.div`
	padding-top: 2%;
	height: 100vh;
	width: 100%;
	@media screen and (max-width: 600px) {
		height : fit-content;
		}

`;

export const ServicesImageDiv = styled.div`

`

export const EmbeddedDiv = styled.div`
height : 100vh;
@media screen and (max-width: 600px) {
	height : fit-content;
	}
padding : 2%;
box-sizing : border-box;
`
export const ContentDiv = styled.div`
display : flex ;
width : 100%;
`

export const ListContainer = styled.div`
flex : 0.6;
display : flex;
flex-direction : column ;
// @media screen and (max-width: 600px) {
// 	flex : 1;

// 	}
`

export const PictureContainer = styled.div`
flex : 0.4;
// @media screen and (max-width: 600px) {
// 	flex : 0;

// 	}
`
export const Software = styled.div`

`
export const Hardware = styled.div`

`
export const SoftwareList = styled.div`
display : flex;
justify-content : space-between;
width : 90%;
@media screen and (max-width: 600px) {
	width : 100%;
flex-direction : column;

	}
padding-top : 2%;
`
export const HardwareList = styled.div`
display : flex;
justify-content : space-between;
width : 90%;
@media screen and (max-width: 600px) {
	width : 100%;
flex-direction : column;

	}
padding-top : 2%;

`

export const ListSpanFont = styled.span`
font-size: 15px;
font-weight: bold;
color : #14A5DE;
text-align : center;
`

export const FlexElectronicImg = styled.img`
 @media screen and (max-width: 600px) {
	display : none;
	}

`

export const FlexElectronicImgMobile = styled.img`
display : none;
@media screen and (max-width: 600px) {
	display : block;
	}
`