import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
	Container,
	TextContainer,
	HeaderText,
	MainText,
	DarkText,
	HeaderSpan
} from "./styles.js";
function AboutUs() {

	return (
		<Container>
			<ScrollAnimation animateIn="fadeInLeft">
					<HeaderSpan>
						<HeaderText>About Us</HeaderText>
					</HeaderSpan>
				</ScrollAnimation>
			<TextContainer>
			<ScrollAnimation animateIn="fadeIn">
				<MainText>
					Aadhya Tech Sol (ATS) was founded by a team of experienced
					professionals from different engineering domains to provide{" "}
					<DarkText>INNOVATIVE</DarkText> and cost-effective solutions with{" "}
					<DarkText>EXCEPTIONAL QUALITY </DarkText> {" "}to{"  "}
					<DarkText>IMPROVE PRODUCTIVITY, REDUCE COST </DarkText>
					and customer needs <DarkText> DELIVERED ON SCHEDULE.</DarkText>
				</MainText>
				</ScrollAnimation>
			</TextContainer>
		</Container>
	);
}

export default AboutUs;
