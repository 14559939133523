import styled from '@emotion/styled'

export const Container =styled.div`
height : calc(100vh - 15vh);
width : 100%;
display : flex;
align-items : center;
justify-content : flex-start ;
padding-left : 2%;
`
export const AnimatedDiv = styled.div`
height : fit-content;
display : flex;
width : 50%;
flex-direction : column;

`
export const TextSpan = styled.span`
line-height : 105px;
font-size: clamp(3rem,7rem,6vw);
font-weight: 800;
letter-spacing: -3px;
overflow : hidden;
`