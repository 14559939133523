import React from 'react'
import {useSpring, animated} from 'react-spring'
import './styles.css'
import {Container ,AnimatedDiv , TextSpan} from './styles'



function FrontPage({WindowSize}) {
  const props = useSpring({
   
    to: {marginTop : "0" , color: '#FED44B'},
    from: {marginTop : "-70px" , color: '#fff'}
  })
  const props1 = useSpring({
    to: {marginTop : "0" , color: '#14a5de'},
    from: {marginTop : "-70px" , color: '#fff'}
  })
  const props2 = useSpring({
    to: {marginTop : "0" ,color: '#FED44B' },
    from: {marginTop : "-70px", color: '#fff'}
  })
  const props3 = useSpring({
    to: {marginTop : "0" ,color: '#14a5de'},
    from: {marginTop : "-70px" , color: '#fff' }
  })

  return (
      <Container 	style={{
        backgroundImage: WindowSize > 600 ? `url(${require("../../../Assets/frontpage.svg")})` : "none",
        backgroundPosition: "center",
        backgroundSize: "cover",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
    }} >     
  <AnimatedDiv>
    <animated.div style={props}><TextSpan>FOCUSED,</TextSpan></animated.div>
    <animated.div style={props1}><TextSpan>INVENTIVE,</TextSpan></animated.div>
    <animated.div style={props2}><TextSpan>DEPENDABLE</TextSpan></animated.div>
    <animated.div style={props3}><TextSpan>ENGINEERING</TextSpan></animated.div>
  </AnimatedDiv>

      </Container>
  )
}

export default FrontPage
