import React from "react";
import { Image } from "antd";
import {
	CardDiv,
	ImageSpan,
	ImageGalleryContainer,
	GalleryImage,
	PhotoDescription,
	HeaderText
} from "./styles";
const Gallery = () => {


	const AnalysisImages = {
		"STATIC ANALYSIS OF FRAME": "STATIC ANALYSIS OF FRAME",
		"DYNAMIC IMPACT LOAD CASE": "DYNAMIC IMPACT LOAD CASE",
		"STATIC AND QUASI STATIC FE ANALYSIS":
			"STATIC AND QUASI STATIC FE ANALYSIS",
		"STRUCTURAL FEA OF CARRIAGE ASSEMBLY":
			"STRUCTURAL FEA OF CARRIAGE ASSEMBLY",
		"FEA - DOOR TRIM": "FEA - DOOR TRIM",
		"MOULD FLOW ANALYSIS": "MOULD FLOW ANALYSIS",
		"THERMAL ANALYSIS": "THERMAL ANALYSIS",
	};


	return (
		<div>

<span style={{ display: "flex", justifyContent: "center" , width : "100%" }}>
					<HeaderText>Gallery</HeaderText>
					</span>

			<ImageGalleryContainer>
				{/* <Image.PreviewGroup>
					{Object.entries(DesignImages).map(([key, value], index) => {
						return (
							<CardDiv key={index}>
								<ImageSpan>
									<GalleryImage
										src={require("../../Assets/DesignSvgs/Original/" +
											value +
											".svg")}
										width={350}
										height={250}
										alt=""
										// style={{ transform: hover === index ? "scale(1.2)" : null }}
									/>
								</ImageSpan>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<PhotoDescription>{key}</PhotoDescription>
								</div>
							</CardDiv>
						);
					})}
				</Image.PreviewGroup>

				<Image.PreviewGroup>
					{Object.entries(ElectricalImages).map(([key, value], index) => {
						return (
							<CardDiv key={index}>
								<ImageSpan>
									<GalleryImage
										src={require("../../Assets/ElecrticalSvgs/" +
											value +
											".svg")}
										width={350}
										height={250}
										alt=""
									/>
								</ImageSpan>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<PhotoDescription>{key}</PhotoDescription>
								</div>
							</CardDiv>
						);
					})}
				</Image.PreviewGroup> */}

				<Image.PreviewGroup>
					{Object.entries(AnalysisImages).map(([key, value], index) => {
						return (
							<CardDiv key={index}>
								<ImageSpan>
									<GalleryImage
										src={require("../../Assets/AnalysisSvgs/" + value + ".svg")}
										width={350}
										height={250}
										alt=""
									/>
								</ImageSpan>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<PhotoDescription>{key}</PhotoDescription>
								</div>
							</CardDiv>
						);
					})}
				</Image.PreviewGroup>
			</ImageGalleryContainer>
		</div>
	);
};

export default Gallery;
