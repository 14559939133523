import styled from "@emotion/styled";
import ImageGallery from "react-image-gallery";
export const Container = styled.div`
max-width : 100% !important;
`;

export const MainText = styled.p`
	font-size: clamp(12px, 19px, 2vw);
	font-weight: 500;
	text-align: justify;
	color: white;
	width: 100%;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 20px;
	padding: 5%;
	margin-top: 2%;
	@media screen and (max-width: 600px) {
		font-size: clamp(1.2rem, 3vw, 3rem);
	}
`;

export const LiDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex: 0.5;
	height: auto;
	padding-left: 2%;
	padding-top: 2%;
	@media screen and (max-width: 600px) {
		padding-left: 25px;
		padding-right: 15px;

		padding-top :30px;
	}
`;

export const UlText = styled.li`
	font-size: clamp(12px, 19px, 2vw);
	font-weight: 500;
	color: #000000;
	line-height: 35px;
	@media screen and (max-width: 600px) {
	line-height: 15px;

	}
`;

export const ConsultanceContainer = styled.div`
	background: url(${require("../../../Assets/consultancy.svg")});
	background-size: cover;
	background-repeat: no-repeat;
	object-fit: cover;
	@media screen and (max-width: 600px) {
		height: fit-content;
	background: url(${require("../../../Assets/Mobile/Subpages/consultancy.svg")});

	}
`;

export const Portfolio = styled.div`
	padding-top: 2%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
	height: fit-content;
	width: 100%;
`;

export const ListsAndCar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 3%;
	// align-items : center;
	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;
		align-items: center;
		display: flex;
	}
`;
export const CarouselR = styled(ImageGallery)``;

export const TextDiv = styled.div`
	height: calc(100vh - 8vh);
	width: 30%;
	flex-direction: column;
	display: flex;
	justify-content: center;
	@media screen and (max-width: 600px) {
		width: 70%;
	}
`;

export const CarouselDiv = styled.div`
	flex: 1;
	@media screen and (max-width: 600px) {
		width: 100%;
		height: fit-content;
		padding-top: 10%;
	}
`;

export const ServicesDiv = styled.div`
	padding-top: 2%;
	height: fit-content;
	width: 100%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
`;
export const AnalysisDiv = styled.div`
	padding-top: 2%;
	height: fit-content;
	width: 100%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
`;

export const ListSpanFont = styled.div`
	font-size: 35px;
	font-weight: bold;
	color: #14a5de;
	padding : 20px 0;
	text-align : center;
	@media screen and (max-width : 600px){
		font-size: 20px;
		padding: 10px 15px;

	}
`;

export const ImageContainer = styled.div`
display : flex;
height : fit-content;
justify-content  : space-between;
padding-top : 30px;
padding-bottom: 20px;

@media screen and (max-width : 600px){
flex-direction : column-reverse;
}
`

export const ResponsiveImage = styled.img`
width: 100%;
height: auto;
@media screen and (max-width : 600px){
	width : 200px;
	height : 200px;
}
`

export const ImageDiv = styled.div`
display: flex;
    align-items: center;
	justify-content : center;
`