import React from "react";
import {
	Container,
	MainText,
	LiDiv,
	AnalysisContianer,
	Portfolio,
	ListsAndCar,
	CarouselR,
	TextDiv,
	CarouselDiv,
	CadContainer,
	AnalysisDiv,
	ImageContainer
} from "./styles";
import ListComponent from '../../List/ListComponent'
import AnimatedHead from '../../Animated Heading/AnimatedHead'
const Manufacture = () => {

    const services = [
        "Engineering Product Design & Development",
        "Prototyping",
        "Modeling & Detailing",
        "Reengineering",
        "JIGS and FIXTURES (MACHINING, ASSEMBLY, WELDING)",
        "Tool, DIE & MOULD",
        "Sheet metal bending",
        "Structure fabrications",
    ]

	const facilities = [
		"BFW, Vertical Machine Centre (X-450, Y-350,Z-400mm)",
		"Jyothi, Vertical Machine Centre (X-800, Y-500,Z-500mm)",
		"Jyothi, Vertical Machine Centre (X-400, Y-300,Z-400mm)",
		"Jyothi, Turning Centre (Ø 200, Between Centre – 500mm)",
		"CNC Bending Machine – 1200mm",
		"Acrylic Laser Cutting Machine",
	];

	const portfolio = [
		"152 Fixtures supplied",
		" 53 Die and Mould design and manufacture",
		"146 Sheet metal parts developed",
		" 53 Plastic parts developed",
		" 10 Micron or less accuracy",
		"6 Design Engineers ",
	];

	const facilitiesImages = [
		{
			original: `${require("../../../Assets/MachiningSvgs/bfw.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/bfw.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/cnc.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/cnc.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/jtc.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/jtc.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/jvmc.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/jvmc.svg")}`,
        },	
        {
			original: `${require("../../../Assets/MachiningSvgs/manual.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/manual.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/milling.svg")}`,
			thumbnail:  `${require("../../../Assets/MachiningSvgs/milling.svg")}`,
		},
	];

	const portfolioImages = [
		{
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 1.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 1.svg")}`,
		},
		{
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 3.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 3.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 4.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 4.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 5.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 5.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 6.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 6.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 7.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 7.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 8.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 8.svg")}`,
        },
        
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 9.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 9.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 10.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 10.svg")}`,
        },
        {
			original: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 11.svg")}`,
			thumbnail: `${require("../../../Assets/MachiningSvgs/portfolio/portfolio 11.svg")}`,
        },
	];

	return (
		<Container>
			<AnalysisContianer>

				<TextDiv>
				
<AnimatedHead Heading="Manufacturing and Prototyping" />
					<MainText>
						Experienced machinists and with industry leading vertical milling
						machine, turning centre, CNC bending machine, acrylic laser cutting
						machine and ram turret milling machine we can manufacture high
						quality components of various sizes. We also provide prototyping of
						components with wire frame 3D printer.
					</MainText>
				</TextDiv>
			</AnalysisContianer>

			

			<AnalysisDiv>
<AnimatedHead Heading="Machining Facilities" Line={true}/>

				<ListsAndCar>
					<LiDiv>
						{facilities.map((item, index) => {
							return (
								<ListComponent items={item}/>
							);
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
							thumbnailPosition="left"
							autoPlay
							lazyLoad
							items={facilitiesImages}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</AnalysisDiv>

			<Portfolio>
<AnimatedHead Heading="Portfolio" Line={true}/>

				<ListsAndCar>
					<LiDiv>
						{portfolio.map((item, index) => {
							return (
								<ListComponent items={item}/>

							);
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
							thumbnailPosition="left"
							autoPlay
							
							lazyLoad
							items={portfolioImages}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</Portfolio>

            <CadContainer>
<AnimatedHead Heading="Services Provided" Line={true}/>
				
				<ListsAndCar>
					<LiDiv style={{alignSelf : "center"}}>
						{services.map((item, index) => {
							return (
								<ListComponent items={item}/>
							);
						})}
					</LiDiv>

					<CarouselDiv>
					<ImageContainer style={{width : "100%"  , height : "80vh"}} src={require("../../../Assets/MachiningSvgs/services.svg")} alt="" />
					</CarouselDiv>
				</ListsAndCar>
			</CadContainer>
		</Container>
	);
};

export default Manufacture;
