import styled from "@emotion/styled";
import { Image } from "antd";

export const ImageGalleryContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	box-sizing: border-box;
	// background : black;
`;

export const CardDiv = styled.div`
	background-color : rgba(98,98,100,.5);
	padding : 1% 1%  0 1% ; 
	box-sizing: border-box !important;
	border-radius : 12px;
	width: 350px;
	margin: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
`;
export const ImageSpan = styled.div`
	width: 100%;
	overflow: hidden;
	border-bottom: 1px solid black;
	background : white;
`;

export const GalleryImage = styled(Image)`
	width: 100%;
	transition: 0.5s all ease-in-out;

	cursor: zoom-in;
`;

export const PhotoDescription = styled.span`
	color: #ffffff;
	text-align: center;
	font-size: larger;
	font-weight: 600;
	cursor: pointer;
	
`;

export const HeaderText = styled.p`
font-size: 40px;
@media screen and (max-width: 600px) {
	font-size: 30px;
}
font-weight: 700;
text-align: center;
width: 100%;
color: #14a5de;
text-shadow: 1px 1px #ffffff;
`