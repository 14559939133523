import styled from "@emotion/styled";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-top: 3%;
	@media screen and (max-width: 600px) {
		height : fit-content;
	}
`;

export const HeaderText = styled.span`
font-size: 40px;
@media screen and (max-width: 600px) {
	font-size: 30px;
}
	font-weight: 700;
	text-align: center;
	width: fit-content;
	color: #14a5de;
`;

export const MobileImage = styled.img`
@media screen and (max-width: 600px) {
	display : block;
}
display : none;

`
export const DesktopImage = styled.img`
@media screen and (max-width: 600px) {
	display : none;
}
`