import styled from '@emotion/styled'
import {Menu} from 'antd'

export const MenuText = styled(Menu.Item)`
font-size : 17px;
font-weight : 500;
color : gray;
`
export const SubText = styled(Menu.Item)`
font-size : 16px;
font-weight : 500;
color : gray;
`


export const HeaderDiv = styled.div`
display : flex;
justify-content : space-between;
align-items : start;
width : 100%;
height : 90px;
padding-right : 20px;
overflow: hidden;
background-color: #000;
position: sticky; 
top: 0; 
width: 100%; 
z-index : 100;
padding-left : 10px;

`