import React from 'react'
import {ListDiv  , YellowCircle ,  ListFont  } from './styles'
const ListComponent = ({items}) => {
    return (
        <div>
            <ListDiv>
            <YellowCircle></YellowCircle>                
                <ListFont>{items}</ListFont>
            </ListDiv>
        </div>
    )
}

export default ListComponent
