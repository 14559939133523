import React from "react";
import {
	Container,
	MainText,
	UlText,
	LiDiv,
	ConsultanceContainer,
	Portfolio,
	ListsAndCar,
	TextDiv,
	CarouselDiv,
	ServicesDiv,
    CarouselR,
	ListSpanFont,
	ImageContainer,
	ResponsiveImage,
	ImageDiv
} from "./styles";
import AnimatedHead from '../../Animated Heading/AnimatedHead'
const Consulting = () => {

	const consultancy = [
		"Soap",
		"Detergents ",
		"Food industry ",
		"Edible Oil",
		"Toothpaste",
	];

	const expert = [
		"Activities involved working out various requirements & monitoring overall project operations including Capex management ",
		"Design drawing, deployment, fabrication and erection & commissioning, for ensuring timely completion",
	
    ];

    const erection = [
        "Anchoring erection & commissioning of machines & equipment and ensuring completion of project in minimum turnaround time effective resource utilisation to maximise the output.",
        "Overseeing installation & commissioning as well as conducting tests & inspections to ensure operational effectiveness of utilities; planning & effectuating maintenance plans for machines and equipment."
    ]
    
    const site =[
        "Anchoring on-site construction activities to ensure completion of project  within the time & cost parameters and effective resource utilisation to maximise the output." ,
"Formulating & implementing stringent systems and quality plans / manuals to ensure high quality standards during all the stages of project."
    ]

	const portfolio = [
		"Lead Design team (L&T) through Concept Design, Front End Engineering Design and Detailed design to implementation of the project",
		"international experience with different companies diversified Products like Personal care, Oral care, food, Beverages and Home care. ",
		"Involved in new greenfield project set-ups & management while spearheading Operations, technology enhancements, implementing lean manufacturing.",
		"Established new manufacturing plant for Unilever in Ethiopia from conceptualization, commissioning to delivery of the project as Project Delivery Consultant, headed implementation of WCM from project design to manufacturing.",
		"Proficient in integration of QMS like WCM, Haccp, other international standards into process and equipment design. ",
		"Total cost of the Project, £ 100 Million, Spearheaded onshoring manufacturing facility for Unilever in Ethiopia from Greenfield project planning to execution.",
        "Activities involved working out various requirements & monitoring overall project operations including Capex management",
        "Design drawing, deployment, fabrication and erection & commissioning, for ensuring timely completion",
	];

	const siteImages = [
		{
			original: `${require("../../../Assets/ConsultancySvgs/site1.svg")}`,
			thumbnail: `${require("../../../Assets/ConsultancySvgs/site1.svg")}`,
        },
        {
			original: `${require("../../../Assets/ConsultancySvgs/site2.svg")}`,
			thumbnail: `${require("../../../Assets/ConsultancySvgs/site2.svg")}`,
        },
        {
			original: `${require("../../../Assets/ConsultancySvgs/site3.svg")}`,
			thumbnail: `${require("../../../Assets/ConsultancySvgs/site3.svg")}`,
        },
        {
			original: `${require("../../../Assets/ConsultancySvgs/site4.svg")}`,
			thumbnail: `${require("../../../Assets/ConsultancySvgs/site4.svg")}`,
		},

	];


	return (
		<Container>
			<ConsultanceContainer>

				<TextDiv>
			<AnimatedHead Heading="Consultancy"/>
					
					<MainText>
						Our expert has 27 Years of professional Engineering experience in
						the areas of Project Management, Project Design, Process
						Enhancement, Quality Management and Team Management with key focus
						on profitability, optimal utilization of resources and Manufacturing
						Excellence
					</MainText>
				</TextDiv>
			</ConsultanceContainer>

			<ServicesDiv>
			<AnimatedHead Heading="Consultancy Services" Line={true}/>
			<ListSpanFont>We provide our expert consultancy in the following :</ListSpanFont>

				<ImageContainer>
					<LiDiv >
						{consultancy.map((item, index) => {
							return (
								<ul key={index}>
									<UlText>{item}</UlText>
								</ul>
							);
						})}
					</LiDiv>
					<ImageDiv>
					<ResponsiveImage src={require("../../../Assets/ConsultancySvgs/services.jpg")} alt=""/>
					</ImageDiv>
					</ImageContainer>
				
			</ServicesDiv>

		

			<Portfolio>
			<AnimatedHead Heading="Portfolio" Line={true}/>

				<ListsAndCar>
					<LiDiv style={{flex: ".9"}}>
						{portfolio.map((item, index) => {
						
							return (
								<ul>
									<UlText>{item}</UlText>
								</ul>
							);
						})}
					</LiDiv>
				</ListsAndCar>
			</Portfolio>
            <ServicesDiv>
				<AnimatedHead Heading="Expertise" Line={true}/>
			<ImageContainer>

					<LiDiv>
						{expert.map((item, index) => {
							return (
								<ul key={index}  > 
									<UlText>{item}</UlText>
								</ul>
							);
						})}
					</LiDiv>
					<ImageDiv >
					<ResponsiveImage src={require("../../../Assets/ConsultancySvgs/expertise.jpg")} alt=""/>
					</ImageDiv>
						</ImageContainer>

			</ServicesDiv>
            <ServicesDiv>
				<AnimatedHead Heading="Site & Construction" Line={true}/>

				<ListsAndCar>
					<LiDiv>
						{site.map((item, index) => {
							return (
								<ul>
									<UlText>{item}</UlText>
								</ul>
							);
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
							thumbnailPosition="left"
							autoPlay
							lazyLoad
							items={siteImages}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</ServicesDiv>

            <ServicesDiv>
				<AnimatedHead Heading="Erection & Commissioning" Line={true}/>
				
				<ImageContainer>

					<LiDiv>
						{erection.map((item, index) => {
							return (
								<ul key={index}  > 
									<UlText>{item}</UlText>
								</ul>
							);
						})}
					</LiDiv>
					<ImageDiv>
					<ResponsiveImage src={require("../../../Assets/ConsultancySvgs/Erection.jpg")} alt=""/>
					</ImageDiv>
						</ImageContainer>

				
			</ServicesDiv>
		</Container>
	);
};

export default Consulting;
