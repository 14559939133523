import styled from "@emotion/styled";

export const GridContainer = styled.div`
	height: 100vh;

	padding-top: 2%;
	@media screen and (max-width: 600px) {
		height: fit-content;
		margin-bottom : 10px;
	}
`;

export const Container = styled.div`
	margin-top: 2%;
	box-sizing: border-box;
	display: flex;
	width: 100%;
	height: 80vh;
	@media screen and (max-width: 600px) {
		height: fit-content;
		flex-direction : column;
		
	}
	cursor: pointer;
`;

export const One = styled.div`
	display: flex;
	width: 20%;
	transition: width 0.7s ease-in;
	:hover {
		width: 100%;
	}
	
	@media screen and (max-width: 600px) {
		width: 100%;
		
	}
`;

export const Two = styled.div`
	display: flex;
	transition: width 0.7s ease-in;

	width: 20%;
	:hover {
		width: 100%;
	
	}
	@media screen and (max-width: 600px) {
		width: 100%;
		
	}
`;

export const Three = styled.div`
	display: flex;
	transition: width 0.7s ease-in;

	width: 20%;
	:hover {
		width: 100%;
		
	}
	@media screen and (max-width: 600px) {
		width: 100%;
		
	}
`;

export const Four = styled.div`
	transition: width 0.7s ease-in;

	display: flex;
	width: 20%;
	:hover {
		width: 100%;
		
	}
	@media screen and (max-width: 600px) {
		width: 100%;
		
	}
`;
export const Five = styled.div`
	transition: width 0.7s ease-in;

	display: flex;
	width: 20%;
	:hover {
		width: 100%;
		
	}
	@media screen and (max-width: 600px) {
		width: 100%;
		
	}
`;

export const Heading = styled.span`
	font-size: clamp(24px, 42px, 3vw);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Vertical = styled.p`
	font-family: Roboto Mono;
	width: 100%;
	text-align: center;
	background: none;
	font-size: clamp(30%, 150%, 3vw);

	margin: 0;
	font-weight: 750;
	text-transform: uppercase;
	color: #ffffff;
`;


export const CurvedRec = styled.div`
	width: fit-content;
	height: auto;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-left: 30px;
	border : 1px solid #FED44B;
	border-radius: 16px;
	background: rgba(0, 0, 0,.5);
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-self : center;
`;

export const LearnButton = styled.button`
	border: 1px black solid;
	width : fit-content;
	background: transparent;
	padding : 6px;
	font-size: clamp(0.5rem, 3vw,1.1rem);
	text-decoration-line : underline;
	border-radius: 6px;
	justify-self: center;
	color : #ffffff ;
	cursor : pointer;
	:hover{
	color : #FED44B;
	}
	border : none; 

`;

export const RectText = styled.p`
	color: #14a5de;
	font-size: clamp(0.5rem, 3vw, 1.2rem);

	font-weight: 600;
	overflow: hidden;
	text-align: -webkit-auto;
	// font-style: italic;
`;

export const HeaderText = styled.span`
font-size: 40px;
	font-weight: 700;

	color: #14a5de;
	@media screen and (max-width: 600px) {
		font-size: 30px;
	}
`;

export const HeaderSpan = styled.span`
	display: flex;
	justify-content: center;

	align-items: center;
`;

export const ContainerWithTitle = styled.div`
display: flex;
justify-content: center;
align-items: start;
width: 100%;
opacity: 1;
@media screen and (max-width: 600px) {
	
	height : 130px;
	
}
`

export const ContainerWithContent = styled.div`
width: 100%;
transition: width 0.5s ease-in;
@media screen and (max-width: 600px) {
	height : 500px;
}
`