import styled from '@emotion/styled'
import {Menu} from 'antd'

export const MenuText = styled(Menu.Item)`
font-size : clamp(12px,17px,2vw);
font-weight : 500;
color : gray;
`
export const SubText = styled(Menu.Item)`
font-size : clamp(12px,15px,2vw);
font-weight : 500;
color : gray;
`




export const HeaderContainer = styled.div`
height : 15vh;
background : #000000;
padding : 20px 5% ;
position : sticky;
top : 0;
display : flex;
align-items : center;
z-index: 5;

`
export const List = styled.span`
color : #14a5de;
font-size : clamp(.5rem,1.5rem,2vw);
font-weight : bold;
list-style-type: none;
  padding: 0;
  display : inline ;
  width : fit-content;
  cursor : pointer;
  height : 100%;
  :hover {
    color : #FED44B;
}
`
export const ListMinimised = styled.span`
color : #14a5de;
font-size : clamp(.2rem,1.3rem,2vw);
font-weight : bold;
list-style-type: none;
  padding: 0;
  display : inline ;
  width : fit-content;
  cursor : pointer;
  height : 100%;
  :hover {
    color : #FED44B;
}
`

export const Link = styled.a`
:active {
    color : #FED44B !important;
}
:hover {
    color : #FED44B !important;
}
color : #14a5de !important;
`
export const MenuItem = styled(Menu.Item)`
&.ant-dropdown-menu-item:hover{
background-color : #FED44B !important;
line-height : 45px;

}
&.ant-dropdown-menu-item{
    line-height : 35px;
}
` 

export const MenuDiv = styled.div`
display  : flex;
justify-content: space-between;
width : 70%;
`