import styled from '@emotion/styled'

export const Container = styled.div`
width : 100%;
background : black;
height : auto;
@media screen and (max-width: 600px) {
    height : fit-content;
}
`

export const ContentContainer = styled.div`
display : flex;
justify-content : space-between;
`

export const MapContainer = styled.div`
padding : 4px;
border-radius : 4px;
width : 35vw;
height : 35vh;
background : #14a5de;
justify-self : flex-end;
@media screen and (max-width: 600px) {
    margin-top : 15px;
    width : 250px;
height : 250px;
}
`

export const ContactContainer = styled.div`
@media screen and (max-width: 600px) {
    padding : 5 !important;
    flex-direction : column;
}
padding : 10px;
width : 100%;
height : max-content;
display : flex;
justify-content : space-between ;
overflow : hidden;
`
export const ContactSpan = styled.span`
width : 5vw ;
color  : white;
`