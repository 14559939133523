import React from "react";
import {
	Container,
	MainText,
	HeaderText,
	UlText,
	LiDiv,
	ElectricalContainer,
	Portfolio,
	ListsAndCar,
	CarouselR,
	TextDiv,
	CarouselDiv,
	CadContainer,
	EmbeddedDiv,
	ServicesImageDiv,
	ContentDiv,
	ListContainer,
	PictureContainer,
	Software,
	Hardware,
	SoftwareList,
	HardwareList,
	ListSpanFont,
	FlexElectronicImg,
	FlexElectronicImgMobile
} from "./styles";
import AnimatedHead from '../../Animated Heading/AnimatedHead'
import ListComponent from '../../List/ListComponent'
const Electronics = () => {
	const [slideIndex, setSlideIndex] = React.useState("");
	const services = [
		"Hardware & Software Interface Analysis",
		"Application software design",
		"Software unit test & Functional test",
		"HIL testing",
		"OBD",
		"DoE",
		"Verification & Validation",
	];

	const portfolio = [
		"HOT DESKING AN IOT PROJECT",
		"ENAMEL RATER DESIGN",
		"ETHERNET INTERFACE MODULE",
		"LASER UNIT POSITIONING ",
		"WAFER SURFACE CONTROLLER",
		"BREATH MONITORING BELT",
	];

	const portfolioImages = [
		{
			original: `${require("../../../Assets/ElecrticalSvgs/Hot desking an iOt project.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/Hot desking an iOt project.svg")}`,
		},
		{
			original: `${require("../../../Assets/ElecrticalSvgs/Enamel rater tester.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/Enamel rater tester.svg")}`,
		},
		{
			original: `${require("../../../Assets/ElecrticalSvgs/Ethernet interface and monitoring module.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/Ethernet interface and monitoring module.svg")}`,
		},
		{
			original: `${require("../../../Assets/ElecrticalSvgs/Laser unit positioning.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/Laser unit positioning.svg")}`,
		},
		{
			original: `${require("../../../Assets/ElecrticalSvgs/Wafer surface controller.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/Wafer surface controller.svg")}`,
		},
		{
			original: `${require("../../../Assets/ElecrticalSvgs/breath monitoring BELT.svg")}`,
			thumbnail: `${require("../../../Assets/ElecrticalSvgs/breath monitoring BELT.svg")}`,
		},
	];

	return (
		<Container>
			<ElectricalContainer>
				<TextDiv>
<AnimatedHead Heading="Electrical and Electronics" />
					<MainText>
						We actively partner with customers in the field of Embedded Hardware
						and Software Turnkey Design Services to expand our expertise to
						develop optimal solutions to maximize profits and revenues and would
						love to contribute to your success. Team is headed by a technical
						director who is an electronic engineer with around 25 years’
						experience in the field of electronics design
					</MainText>
				</TextDiv>
			</ElectricalContainer>

			<CadContainer>
<AnimatedHead Heading="Services Provided" Line={true}/>

				<ListsAndCar>
					<LiDiv style={{ height: "max-content", alignSelf: "center" }}>
						{services.map((item, index) => {
							return (
								<ListComponent items={item}/>
							);
						})}
					</LiDiv>

					<ServicesImageDiv>
						<img
							style={{ width: "90%" }}
							src={require("../../../Assets/ElecrticalSvgs/Services.svg")}
							alt=""
						/>
					</ServicesImageDiv>
				</ListsAndCar>
			</CadContainer>

			<Portfolio>
<AnimatedHead Heading="Portfolio" Line={true}/>

				<ListsAndCar>
					<LiDiv>
						{portfolio.map((item, index) => {
							return (
								<ListComponent items={item}/>
							);
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
							thumbnailPosition="left"
							autoPlay
							onSlide={(e) => setSlideIndex(e)}
							lazyLoad
							items={portfolioImages}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</Portfolio>
			<EmbeddedDiv>
<AnimatedHead Heading="Embedded System Capability" Line={true}/>

				<ContentDiv>
					<ListContainer>
						<Software>
						<AnimatedHead Heading="Software" Line={true}/>
							<SoftwareList>
								<div>
									<ListSpanFont>TOOLS</ListSpanFont>
									<ul>
										<UlText>XILINX and ALTERA</UlText>
										<UlText>Various IDEs</UlText>
										<UlText>LabVIEW</UlText>
										<UlText>MATLAB/SIMULINK</UlText>
									</ul>
								</div>
								<div>
									<ListSpanFont>RTOS -BSPs/Drivers</ListSpanFont>
									<ul>
										<UlText>Linux</UlText>
										<UlText>Vx works</UlText>
										<UlText>DSP/BIOS and BSPs</UlText>
										<UlText>C, C++, GUI</UlText>
									</ul>
								</div>

								<div>
									<ListSpanFont>SIGNAL PROCESSING</ListSpanFont>
									<ul>
										<UlText>Digital Communication</UlText>
										<UlText>Modelling and Simulation</UlText>
									</ul>
								</div>
							</SoftwareList>
						</Software>
						<AnimatedHead Heading="Hardware" Line={true}/>

						<Hardware>
							<HardwareList>
                            
								<div>
									<ListSpanFont>PROCESSORS</ListSpanFont>
									<ul>
										<UlText>Analog Devices</UlText>
										<UlText>ARM</UlText>
										<UlText>ST</UlText>
										<UlText>TI</UlText>
									</ul>
								</div>


                                <div>
									<ListSpanFont>DEVICES INTERFACE</ListSpanFont>
                                    
									<ul>
										<UlText>Storage – DDR, Flash, CF,SD, HDD,</UlText>
										<UlText>HMI – LCD, Touch Screen, KBD</UlText>
										<UlText>LabVIEW</UlText>
										<UlText>
											Communication – Serial, USB, CAN, BUS, Ethernet
										</UlText>
									</ul>
								</div>

								<div>
									<ListSpanFont>FPGA</ListSpanFont>
									<ul>
										<UlText>XILINX</UlText>
										<UlText>ACTEL</UlText>
										<UlText>ALTERA</UlText>
										<UlText>MICROSEMI</UlText>
									</ul>
								</div>
                              
							</HardwareList>
						</Hardware>
					</ListContainer>
					<PictureContainer>
						<FlexElectronicImg
							src={require("../../../Assets/ElecrticalSvgs/Embedded.svg")}
							alt=""
							style={{ height: "80vh" }}
						/>
						<FlexElectronicImgMobile	
						src={require("../../../Assets/Mobile/EmbeddedM.svg")}
							alt=""
							
						/>
					</PictureContainer>
				</ContentDiv>
			</EmbeddedDiv>
		</Container>
	);
};

export default Electronics;
