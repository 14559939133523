import styled from "@emotion/styled";

export const HeaderSpan = styled.span`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const HeaderText = styled.span`
	font-size: 40px;
	@media screen and (max-width: 600px) {
		font-size: 30px;
	}
	font-weight: 700;
	text-align: center;
	width: 100%;
	background: #14a5de;
	color: rgb(251,211,77);
`;
export const HeaderTextLine = styled.span`
font-size: 40px;
@media screen and (max-width: 600px) {
	font-size: 30px;
    text-underline-offset: 5px;

}
	font-weight: 700;
	text-align: center;
	width: 100%;
	color: #14a5de;
    text-decoration-color: #FBD34D;
    text-decoration-line: underline;
    /* background: #fbd34c; */
    text-decoration-style: solid;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;

`