import React from "react";

import {
	Container,
	MainText,
	LiDiv,
	DesignContianer,
	Portfolio,
	ListsAndCar,
	CarouselR,
	TextDiv,
	CarouselDiv,
	CadContainer,
	MobileText
} from "./style";
import ListComponent from '../../List/ListComponent'
import AnimatedHead from '../../Animated Heading/AnimatedHead'
const Subdesign = ({WindowSize}) => {
	const [slideIndex, setSlideIndex] = React.useState("");

	const cad = [
		"New design",
		"Reverse engineering",
		"Product design",
		"Fixture design",
		" Sheet metal design",
		" Drawing/detailing",
		"Print to model audits",
		"CAD customization",
		"GPDM",
		"Electrical CAD conversion",
	];

	const portfolio = [
		"SCREW CONVEYOR",
		"BALING MACHINE – CAD",
		"BALING MACHINE – Hydraulic Circuit",
		"BALING MACHINE – Electrical circuit",
		"HIGH VOLTAGE CONTROL PANEL",
		"MILITARY ATS 19 INCH RACK",
		"Mould correction design",
	];


	const images = [
		{
			original: `${require("../../../Assets/DesignSvgs/Original/bailing machine CAD.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/bailing machine CAD.svg")}`,
		},
		{
			original: `${require("../../../Assets/DesignSvgs/Original/bailing machine hydraulic circuit.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/bailing machine hydraulic circuit.svg")}`,
		},
		{
			original: `${require("../../../Assets/DesignSvgs/Original/High voltage control panel.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/High voltage control panel.svg")}`,
		},
		{
			original: `${require("../../../Assets/DesignSvgs/Original/Military ats 19 inch rack.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/Military ats 19 inch rack.svg")}`,
		},
		{
			original: `${require("../../../Assets/DesignSvgs/Original/Mould correction design.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/Mould correction design.svg")}`,
		},
		{
			original: `${require("../../../Assets/DesignSvgs/Original/Mould correction design.svg")}`,
			thumbnail: `${require("../../../Assets/DesignSvgs/Original/Mould correction design.svg")}`,
		},
		
	];

const cadImages = [
	{
		original: `${require("../../../Assets/DesignSvgs/Original/Reverse engineering.svg")}`,
		thumbnail: `${require("../../../Assets/DesignSvgs/Original/Reverse engineering.svg")}`,
	},
	{
		original: `${require("../../../Assets/DesignSvgs/Original/screw convyer.svg")}`,
		thumbnail: `${require("../../../Assets/DesignSvgs/Original/screw convyer.svg")}`,
	},
]

	return (
		<Container>
			<DesignContianer>

{WindowSize >600 ? <TextDiv>
<AnimatedHead Heading="Design"/>
					<MainText>
						From new product development designs, laying out hydraulic and
						electrical circuits to reverse engineering of an existing product,
						we can do it all. With our experienced team of designers and access
						to the latest designing software, we study the needs of our clients
						thoroughly and deliver the best service there is in design domain
						for customer satisfaction.
					</MainText>
				</TextDiv> : <AnimatedHead Heading="Design"/>}
			</DesignContianer>

<MobileText>
	
<TextDiv>
					<MainText>
						From new product development designs, laying out hydraulic and
						electrical circuits to reverse engineering of an existing product,
						we can do it all. With our experienced team of designers and access
						to the latest designing software, we study the needs of our clients
						thoroughly and deliver the best service there is in design domain
						for customer satisfaction.
					</MainText>
				</TextDiv>
</MobileText>

			<CadContainer>
            
<AnimatedHead Heading="CAD Services" Line={true} />

            <ListsAndCar>
                <LiDiv>
                    {cad.map((item, index) => {
                        return (
                            <ListComponent key={index} items={item}/>
                        );
                    })}
                </LiDiv>

                <CarouselDiv>
                    <CarouselR
                       thumbnailPosition="left"
                        autoPlay
                        lazyLoad
                        items={cadImages}
                    />
                </CarouselDiv>
            </ListsAndCar>
        </CadContainer>
			
			<Portfolio>
		
<AnimatedHead Heading="Portfolio" Line={true} />

				<ListsAndCar>
					<LiDiv>
						{portfolio.map((item, index) => {
							return (
								<ListComponent key={index} items={item}/>
						
								);
						})}
					</LiDiv>

					<CarouselDiv>
						<CarouselR
						thumbnailPosition="left"
							autoPlay
							onSlide={(e) => setSlideIndex(e)}
							lazyLoad
							items={images}
						/>
					</CarouselDiv>
				</ListsAndCar>
			</Portfolio>
		
		
		</Container>
	);
};

export default Subdesign;
