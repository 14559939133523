import styled from "@emotion/styled";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export const Container = styled.div``;

export const MainText = styled.p`
	padding-top: 3%;
	font-size: clamp(12px, 19px, 2vw);
	font-weight: 500;
	text-align: justify;
	color: #626264;
	width: 100%;
	padding-left: 30px;
	padding-right: 30px;
	@media screen and (max-width: 600px) {
		font-size: clamp(1.2rem, 3vw, 3rem);
	}
`;

export const LiDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex: 0.8;
	max-height: 70vh;
	padding-left: 2%;
	@media screen and (max-width: 600px) {
		padding: 20% 0;
	}
`;

export const AnalysisContianer = styled.div`
	background: url(${require("../../../Assets/analysis.svg")});
	background-size: cover;
	background-repeat: no-repeat;
	object-fit: cover;
	height: calc(100vh - 15vh);
	@media screen and (max-width: 600px) {
		height: fit-content;
	background: url(${require("../../../Assets/Mobile/Subpages/analysis.svg")});

	}
`;

export const Portfolio = styled.div`
	height: calc(100vh - 8vh);
	width: 100%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
`;

export const ListsAndCar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 3%;
	align-items: center;
	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;
		align-items: center;
		display: flex;
	}
`;
export const CarouselR = styled(ImageGallery)`
	&&.image-gallery-slide &&.image-gallery-image {
		width: 10% !important;
		object-fit: contain;
	}
`;

export const TextDiv = styled.div`
	height: calc(100vh - 8vh);
	width: 30%;
	flex-direction: column;
	display: flex;
	justify-content: center;
	@media screen and (max-width: 600px) {
		width: 70%;
	}
`;

export const CarouselDiv = styled.div`
	width: 60%;
	height: 70vh;
	@media screen and (max-width: 600px) {
		width: 100%;
		height: fit-content;
		padding-top: 10%;
	}
`;

export const CadContainer = styled.div`
	padding-top: 2%;
	height: calc(100vh - 8vh);
	width: 100%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
`;
export const AnalysisDiv = styled.div`
	height: calc(100vh - 8vh);
	width: 100%;
	@media screen and (max-width: 600px) {
		height: fit-content;
	}
`;
