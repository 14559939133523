import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from '@ant-design/icons';
import logo from "../../Assets/logo.svg";
import { useHistory } from "react-router-dom";

import { HeaderContainer, List ,Link,MenuItem , MenuDiv , ListMinimised } from "./style";


const Header = ({scrollToBottom,scrollToClient , WindowSize}) => {
	const history = useHistory();
	const [scrollPosition, setScrollPosition] = React.useState(false);
	const handleScroll = () => {
		const position = window.pageYOffset;
		if(position > 200){
			setScrollPosition(true);
		}
		else{
			setScrollPosition(false)
		}
	};
	
	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
	
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	const options = {
		analysis: "Analysis",
		design: "Design",
		"electrical-electronics": "Electrical and Electronics",
		"manufacturing-prototyping": "Manufacturing and Prototyping",
		consultancy: "Consultancy",
	};

	const menu = (
		<Menu>
				{Object.entries(options).map(([key, value], index) => {
			return <MenuItem style={{fontSize : "1.4rem" , fontWeight:"600"}} key={index} onClick={() => history.push("/" + key)}>{value}</MenuItem>
		})}
			
		</Menu>
	);
	
	 
	return (
		<>
			{scrollPosition && <HeaderContainer style={{height : "8vh" , paddingLeft : "8%" , visibility : scrollPosition ? "visible" : "hidden" ,
}}>
				<span style={{ marginRight: "5%" }}>
					<img
						
						src={require("../../Assets/logobrand.svg")}
						alt=""
						onClick={() => {
							history.push("/");
						}}
						style={{ cursor: "pointer", width : "50px" }}
					/>
				</span>

				<MenuDiv style={{margin : "0"}}>
					<ListMinimised onClick={() => {
							history.push("/");
						}}>Home</ListMinimised>
					<ListMinimised><Dropdown arrow  overlay={menu}><Link style={{color : "#14a5de"}}>Services <DownOutlined style={{fontSize : "10px" ,color : '#14a5de', fontWeight : "700"}}/></Link></Dropdown></ListMinimised>
					<ListMinimised onClick={() => {
							history.push("/about-us")}}>About Us</ListMinimised>
					<ListMinimised onClick={() => {
							history.push("/gallery");
						}}>Gallery</ListMinimised>
					<ListMinimised onClick={()=> scrollToClient()}>Testimonials</ListMinimised>
					<ListMinimised onClick={()=> scrollToBottom()}>Contact Us</ListMinimised>
				</MenuDiv>
			</HeaderContainer> }
				<HeaderContainer style={{ visibility : scrollPosition ? "hidden" : "visible" }}>
				<span style={{ marginRight: "5%" }}>
					<img
						src={logo}
						alt=""
						onClick={() => {
							history.push("/");
						}}
						style={{ cursor: "pointer" }}
					/>
				</span>

				<MenuDiv>
					<List onClick={() => {
							history.push("/");
						}}>Home</List>
					<List><Dropdown arrow  overlay={menu}><Link style={{color : "#14a5de"}}>Services <DownOutlined style={{fontSize : "14px" ,color : '#14a5de', fontWeight : "700"}}/></Link></Dropdown></List>
					<List onClick={() => {
							history.push("/about-us")}}>About Us</List>
					<List onClick={() => {
							history.push("/gallery");
						}}>Gallery</List>
					<List onClick={()=> scrollToClient()}>Testimonials</List>
					<List onClick={()=> scrollToBottom()}>Contact Us</List>
				</MenuDiv>
			</HeaderContainer>

		</>
	);
};

export default Header;
