import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { HeaderSpan, HeaderText, HeaderTextLine } from "./styles";
const AnimatedHead = ({ Heading, Line }) => {
	return (
		<ScrollAnimation animateIn={Line ?"fadeIn" : "fadeInLeft"} animateOnce={true}>
			<HeaderSpan>
				{Line ? (
					<HeaderTextLine>{Heading}</HeaderTextLine>
				) : (
					<HeaderText>{Heading}</HeaderText>
				)}
			</HeaderSpan>
		</ScrollAnimation>
	);
};

export default AnimatedHead;
