import React from 'react'
import {Drawer , Menu} from 'antd'
import {HeaderDiv ,MenuText ,SubText } from './styles'
import headerlogo from '../../../Assets/headerlogo.svg'
import logo from '../../../Assets/Mobile/headerlogo.svg'
import { useHistory } from "react-router-dom";

const HeaderMobile = ({scrollToBottom}) => {
	const history = useHistory();

    const [visible , setVisible] = React.useState(false)
    const ham = <svg  onClick={()=> setVisible(true)} style={{cursor : "pointer" , marginTop : "35px"}} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.50008 5C2.27907 5 2.06711 5.0878 1.91083 5.24408C1.75455 5.40036 1.66675 5.61232 1.66675 5.83333C1.66675 6.05435 1.75455 6.26631 1.91083 6.42259C2.06711 6.57887 2.27907 6.66667 2.50008 6.66667H22.5001C22.7211 6.66667 22.9331 6.57887 23.0893 6.42259C23.2456 6.26631 23.3334 6.05435 23.3334 5.83333C23.3334 5.61232 23.2456 5.40036 23.0893 5.24408C22.9331 5.0878 22.7211 5 22.5001 5H2.50008ZM1.66675 12.5C1.66675 12.279 1.75455 12.067 1.91083 11.9107C2.06711 11.7545 2.27907 11.6667 2.50008 11.6667H22.5001C22.7211 11.6667 22.9331 11.7545 23.0893 11.9107C23.2456 12.067 23.3334 12.279 23.3334 12.5C23.3334 12.721 23.2456 12.933 23.0893 13.0893C22.9331 13.2455 22.7211 13.3333 22.5001 13.3333H2.50008C2.27907 13.3333 2.06711 13.2455 1.91083 13.0893C1.75455 12.933 1.66675 12.721 1.66675 12.5V12.5ZM1.66675 19.1667C1.66675 18.9457 1.75455 18.7337 1.91083 18.5774C2.06711 18.4211 2.27907 18.3333 2.50008 18.3333H22.5001C22.7211 18.3333 22.9331 18.4211 23.0893 18.5774C23.2456 18.7337 23.3334 18.9457 23.3334 19.1667C23.3334 19.3877 23.2456 19.5996 23.0893 19.7559C22.9331 19.9122 22.7211 20 22.5001 20H2.50008C2.27907 20 2.06711 19.9122 1.91083 19.7559C1.75455 19.5996 1.66675 19.3877 1.66675 19.1667V19.1667Z" fill="white"/>
    </svg>
    const hlogo = <span style={{display : 'flex',justifyContent : "Center" , width : "100%", paddingRight: "24px",}} > <img  src={headerlogo} alt="Not found"/></span>
    const { SubMenu } = Menu

    return (
        <HeaderDiv>          
            <img onClick={() => {
							history.push("/");
						}} src={logo} height="100" width="100" alt=""/>

            {/* <h1 style={{marginRight : "25%" , fontWeight : "700" , color : "#F2D571"}}>clients</h1> */}
            {ham}

            <Drawer
          title={hlogo}
          onClose={() =>setVisible(false)}
          closable={false}
          visible={visible}
          key="right"
        >

        

            <Menu
        // onClick={}
        style={{ width: 200  }}
        mode="inline"
      >
          <MenuText  onClick={() => {
							history.push("/");
						}}>
          Home
          </MenuText>
          <SubMenu
      key="sub1"
      style={{ padding : 0 , margin : 0}}
      title={
        <span>
<p style={{fontSize : "17px" , fontWeight:"500" , color : "gray"}} > Services</p>
          
        </span>
      }
    > <SubText onClick={() => {
      history.push("/analysis");
    }}>
    Analysis
    </SubText>
    <SubText onClick={() => {
							history.push("/design");
						}}>
          Design
          </SubText>
          <SubText onClick={() => {
							history.push("/electrical-electronics");
						}}>
          Electrical and Electronics
          </SubText>
          <SubText onClick={() => {
							history.push("/manufacturing-prototyping");
						}}>
          Manufacturing and Prototyping
          </SubText>
          <SubText onClick={() => {
							history.push("/consultancy");
						}}>
          Consultancy
          </SubText>
          
          </SubMenu>
          <MenuText  onClick={() => {
							history.push("/about-us")}}>
          About Us
          </MenuText>
          <MenuText onClick={() => {
							history.push("/gallery");
						}}>
        Gallery
          </MenuText>
          <MenuText>
          Testimonials
          </MenuText>
          <MenuText onClick={()=> scrollToBottom()}>
          Contact Us
          </MenuText>
         </Menu>
        </Drawer>
        </HeaderDiv>
    )
}


export default HeaderMobile
