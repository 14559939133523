import styled from '@emotion/styled'

export const ListDiv = styled.div`
max-width : 90%;
height: fit-content;
margin-bottom: 15px;
background: #13A5DF;
display : flex;
justify-content : end ;
align-items : center;
padding-left : 10%;
@media screen and (max-width: 600px) {
padding-left : 20px;
width : 300px;

}
`
export const YellowCircle  = styled.div`
background-color: #FBD34C;
border-radius : 45px;
position : absolute;
height : 15px;
width :  15px;
margin: 0 -2%;
@media screen and (max-width: 600px) {
margin: -10%;
    
    }
`
export const ListFont = styled.span`
font-size : 1.2rem;
color : #000000;
font-weight : 400;
@media screen and (max-width: 600px) {
    font-size : 1rem;       
        }
`