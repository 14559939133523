import styled from "@emotion/styled";
import ImageGallery from "react-image-gallery";
export const Container = styled.div``;

export const MainText = styled.p`
font-size: clamp(12px, 19px, 2vw);
font-weight: 500;
text-align: justify;
color: white;
width: 100%;
border-radius: 20px;
padding: 5%;
margin-top: 2%;
@media screen and (max-width: 600px) {
	font-size: clamp(1.2rem, 3vw, 3rem);
}
`;


export const LiDiv = styled.div`
	display: flex;
	flex-flow: column nowrap;
	flex: 0.5;
	height: auto;
	padding-left : 2%;
	@media screen and (max-width: 600px) {
		padding : 20% 0;
	}
`;


export const AnalysisContianer = styled.div`
	background: url(${require("../../../Assets/manufacture.svg")});
	background-size: cover;
    background-repeat: no-repeat;
    object-fit : cover;
	@media screen and (max-width: 600px) {
		height : fit-content;
	background: url(${require("../../../Assets/Mobile/Subpages/machining.svg")});
		}
`;

export const Portfolio = styled.div`
padding-top : 2%;

	height: 100vh;
	width: 100%;
	@media screen and (max-width: 600px) {
		height : fit-content;
		}
`;

export const ListsAndCar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top : 3%;
	align-items: center;
	@media screen and (max-width: 600px) {
		flex-direction : column-reverse;
		align-items : center;
	display: flex;
	
		}
`;
export const CarouselR = styled(ImageGallery)``;

export const TextDiv = styled.div`
height: calc(100vh - 8vh);
	width: 45%;
	flex-direction: column;
	display: flex;
	justify-content : center;
	@media screen and (max-width: 600px) {
		width: 70%;
			}
`;

export const CarouselDiv = styled.div`
    flex: 1;
    @media screen and (max-width: 600px) {
		width: 100%;
	height : fit-content;
	padding-top : 10%;

			}
`;

export const CadContainer = styled.div`
padding-top : 2%;
height: 100vh;
width: 100%;
@media screen and (max-width: 600px) {
	height : fit-content;
	}
`
export const AnalysisDiv = styled.div`
padding-top : 2%;
height: 100vh;
width: 100%;
@media screen and (max-width: 600px) {
	height : fit-content;
	}
`
export const ImageContainer = styled.img`
width : 100%;
height : 80vh;
@media screen and (max-width : 600px){
	height: auto !important;
}
`